import { Switch } from 'react-router-dom';

import Route from './Route';
import Login from '../pages/Login';
import Projects from '../pages/Projects';
import EditProject from '../pages/EditProject';
import Categories from '../pages/Categories';
import Materials from '../pages/Materials';
import Users from '../pages/Users';
import Payments from '../pages/Payments';
import Vouchers from '../pages/Vouchers';
import Profile from '../pages/Profile';
import EditProfile from '../pages/EditProfile';
import AddUser from '../pages/AddUser';
import EditUser from '../pages/EditUser';
import Reports from '../pages/Reports';
import Config from '../pages/Configs';
import WithdrawalRequests from '../pages/WithdrawalRequests';
import Terms from '../pages/Terms';
import Indications from '../pages/Indications';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/projects" exact component={Projects} isPrivate />
      <Route path="/project/:id/edit" component={EditProject} isPrivate />
      <Route path="/payments" component={Payments} isPrivate />
      <Route path="/categories" component={Categories} isPrivate />
      <Route path="/materials" component={Materials} isPrivate />
      <Route path="/users" exact component={Users} isPrivate />
      <Route path="/users/add" component={AddUser} isPrivate />
      <Route path="/user/:id/edit" component={EditUser} isPrivate />
      <Route path="/vouchers" component={Vouchers} isPrivate />
      <Route path="/terms" component={Terms} isPrivate />
      <Route path="/indications" component={Indications} isPrivate />
      <Route path="/withdrawals" component={WithdrawalRequests} isPrivate />
      <Route path="/profile" exact component={Profile} isPrivate />
      <Route path="/profile/edit" component={EditProfile} isPrivate />
      <Route path="/reports" component={Reports} isPrivate />
      <Route path="/configs" component={Config} isPrivate />
    </Switch>
  );
}
