import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';

import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import InputCheckBox from '../../components/InputCheckBox';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import { Container, ButtonsContainer, InputContainer } from './styles';

function formatToSelectOption(arr) {
  return arr.map(i => ({ label: i.type, value: i.type }));
}

const EditUser = () => {
  const { id } = useParams();
  const { loading } = useAuth();
  const formRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [userTypes, setUserTypes] = useState(null);

  async function setUserTypeField(value) {
    formRef.current.setFieldValue('userType', { value, label: value });
  }

  async function setFormData(data) {
    const formData = data;

    if (data.birthDate) {
      const [date] = data.birthDate.split('T');
      formData.birthDate = date;
    }

    if (!formRef.current) return;
    formRef.current.setData(formData);
    setUserTypeField(formData.type);
  }

  useEffect(() => {
    if (loading) {
      return;
    }

    (async () => {
      try {
        const { data } = await api.get(`/admin/user/${id}`);
        setFormData(data);
      } catch (err) {
        Notification({
          message: 'Erro ao buscar dados do usuário',
          type: Notification.types.ERROR,
        });
      } finally {
        setLoading(false);
      }
    })();
    (async () => {
      try {
        const { data } = await api.get('/admin/user_types');
        setUserTypes(formatToSelectOption(data));
      } catch (err) {
        Notification({
          message: 'Erro ao buscar tipos de usuário',
          type: Notification.types.ERROR,
        });
      }
    })();
  }, [loading]);

  async function handleSubmit(data) {
    formRef.current.setErrors({});
    try {
      const birthDate = data.birthDate !== '' ? data.birthDate : null;

      await api.put(`/admin/user/${id}`, { ...data, birthDate });
      Notification({
        message: 'Usuário atualizado com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
        if (err.response.data.errors) {
          formRef.current.setErrors(err.response.data.errors);
        }
      } else {
        Notification({
          message: 'Erro ao editar usuário',
          type: Notification.types.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <PageHeader showAddButton={false} title="Editar usuário" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <p>Dados pessoais</p>
          <Input name="name" label="Nome" />
          <Input name="email" label="E-mail" />
          <InputMask name="cpf" label="CPF" mask="999.999.999-99" />
          <InputContainer>
            <Input type="date" name="birthDate" label="Data de nascimento" />
            <InputSelect
              name="userType"
              options={userTypes}
              label="Tipo de usuário"
              noOptionsMessage="Nenhum resultado encontrado"
              isLoading={userTypes === null}
            />
          </InputContainer>
          <InputContainer>
            <Input type="password" name="password" label="Senha" />
            <Input
              type="password"
              name="confirmPassword"
              label="Confirmar senha"
            />
          </InputContainer>
          <InputCheckBox
            id="verified"
            type="checkbox"
            name="verified"
            label="Verificado"
          />
          <InputCheckBox
            type="checkbox"
            name="designerPremium"
            label="Designer Premium"
          />
        </Form>
        <ButtonsContainer>
          <Button
            text="Salvar dados"
            primary
            onClick={() => formRef.current.submitForm()}
          />
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default EditUser;
