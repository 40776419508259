import {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';

import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [cookies, setCookies, removeCookies] = useCookies();

  function cleanCookies() {
    removeCookies('token', { path: '/' });
    removeCookies('userImage', { path: '/' });
    removeCookies('userName', { path: '/' });
  }

  useEffect(() => {
    (() => {
      try {
        if (cookies.token) {
          api.defaults.headers.authorization = `Bearer ${cookies.token}`;
        }
      } catch (err) {
        cleanCookies();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const signIn = useCallback(
    async ({ newToken, newUserImage, newUserName }) => {
      api.defaults.headers.authorization = `Bearer ${newToken}`;
      setCookies('token', newToken, { maxAge: 86400, path: '/' });
      setCookies('userImage', newUserImage, { maxAge: 86400, path: '/' });
      setCookies('userName', newUserName, { maxAge: 86400, path: '/' });
    },
    []
  );

  const signOut = useCallback(async () => {
    cleanCookies();
  }, []);

  const updateUserImage = useCallback(async imageUrl => {
    removeCookies('userImage', { path: '/' });
    setCookies('userImage', imageUrl, { path: '/' });
  });

  const updateUserName = useCallback(async userName => {
    removeCookies('userName', { path: '/' });
    setCookies('userName', userName, { path: '/' });
  });

  return (
    <AuthContext.Provider
      value={{
        userImage: cookies.userImage,
        isLoggedIn: cookies.token,
        userType: cookies.userType,
        userName: cookies.userName,
        userToken: cookies.token,
        loading,
        signIn,
        signOut,
        updateUserImage,
        updateUserName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
