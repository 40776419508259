import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { Label, CustomInputMask, InputContainer } from './styles';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
};

const InputMask = ({ name, label, mask, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <InputContainer>
      {label && <Label htmlFor={fieldName}>{label}</Label>}
      <CustomInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        mask={mask}
        {...rest}
      />
      {error && (
        <span style={{ color: '#f00', marginTop: 5, fontWeight: 500 }}>
          {error}
        </span>
      )}
    </InputContainer>
  );
};

InputMask.propTypes = propTypes;

export default InputMask;
