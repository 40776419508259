import { useState } from 'react';

import { TabsContainer, Tab } from './styles';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  function handleClickTab(tab) {
    setActiveTab(tab);
  }

  return (
    <TabsContainer>
      <ol style={{ borderBottom: '1px solid #E4F5FC', paddingLeft: 0 }}>
        {children.map(child => {
          const { label } = child.props;

          return (
            <Tab
              active={activeTab === label}
              key={label}
              onClick={() => handleClickTab(label)}
            >
              {label}
            </Tab>
          );
        })}
      </ol>
      <div>
        {children.map(child => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </TabsContainer>
  );
};

export default Tabs;
