import { useEffect, useState } from 'react';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import Table from '../../components/Table';

import { Container } from './styles';
import DeleteModal from '../../components/AlertModal';
import PageHeader from '../../components/PageHeader';
import { SortTypes } from '../../hooks/sortableData';

const itemLabels = {
  owner: { label: 'Proprietário do link' },
  registers: { label: 'Usuários cadastrados' },
  createdAt: { label: 'Criado em', dataType: SortTypes.DATE },
};

const Indications = () => {
  const { loading } = useAuth();
  const [indications, setIndications] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedIndication, setSelectedIndication] = useState({});

  async function fetchNotifications() {
    if (!loading) {
      try {
        const { data } = await api.get('/admin/indications');
        const formatedIndications = data.map(i => ({
          ...i,
          used: i.used ? 'Sim' : 'Não',
          owner: i.owner?.name || '',
        }));
        setIndications(formatedIndications);
      } catch (err) {
        const error = err.response
          ? err.response.data.error
          : 'Ocorreu um erro ao buscar termos';

        Notification({ message: error, type: Notification.types.ERROR });
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (!loading) {
      fetchNotifications();
    }
  }, [loading]);

  function handleDeleteButtonClick(indication) {
    setSelectedIndication(indication);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
    setTimeout(() => setSelectedIndication({}), DeleteModal.closeTimeout);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/indication/${selectedIndication.id}`);
      fetchNotifications();
      Notification({
        message: 'Indicação excluída com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao excluir Indicação';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title="Excluir indicação"
        text={`Deseja realmente excluir a indicação criado por ${selectedIndication.owner}?`}
        type="delete"
      />
      <Container>
        <PageHeader title="Indicações" showAddButton={false} />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={indications}
            headers={itemLabels}
            showEdit={false}
            onDelete={handleDeleteButtonClick}
          />
        )}
      </Container>
    </>
  );
};

export default Indications;
