import PropTypes from 'prop-types';

import { StyledButton, ButtonText } from './styles';

const propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  primary: PropTypes.bool,
  textBold: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  warning: PropTypes.bool,
};

const defaultProps = {
  textBold: false,
  primary: false,
  warning: false,
  type: 'button',
};

const Button = ({ text, primary, onClick, textBold, warning, type }) => (
  <StyledButton
    primary={primary}
    warning={warning}
    onClick={onClick}
    type={type}
  >
    <ButtonText primary={primary} bold={textBold} warning={warning}>
      {text}
    </ButtonText>
  </StyledButton>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
