import PropTypes from 'prop-types';
import { FiPlusCircle } from 'react-icons/fi';

import { Header, AddButton } from './styles';

const propTypes = {
  title: PropTypes.string.isRequired,
  onAddButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  showAddButton: PropTypes.bool,
};

const defaultProps = {
  onAddButtonClick: () => {},
  buttonText: 'Adicionar novo',
  showAddButton: true,
};

const PageHeader = ({ title, onAddButtonClick, buttonText, showAddButton }) => (
  <Header>
    <h2>{title}</h2>
    {showAddButton && (
      <AddButton onClick={onAddButtonClick}>
        {buttonText} <FiPlusCircle size={18} />
      </AddButton>
    )}
  </Header>
);

PageHeader.defaultProps = defaultProps;
PageHeader.propTypes = propTypes;

export default PageHeader;
