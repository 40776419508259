import styled from 'styled-components';

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  > button:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
  },
};

const Container = styled.div`
  h3 {
    margin-bottom: 0.2rem;
    margin-top: 1.2rem;
  }

  p {
    margin: 0.2rem 0;
  }
`;

export { Headline, ButtonsContainer, Container, modalStyles };
