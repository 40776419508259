import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

import { SelectContainer, Label } from './styles';

const optionPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

const propTypes = {
  options: PropTypes.arrayOf(optionPropTypes).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

const defaultProps = {
  placeholder: '',
  noOptionsMessage: '',
  isLoading: false,
};

const InputSelect = ({
  name,
  label,
  noOptionsMessage,
  options,
  placeholder,
  isLoading,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      setValue: (ref, value) => {
        ref.select.setValue(value);
      },
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value;
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      clearValue: ref => {
        ref.select.clearValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <SelectContainer>
      <Label htmlFor="select">{label}</Label>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        id="select"
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder={placeholder}
        isMulti={rest.isMulti}
        noOptionsMessage={() => noOptionsMessage}
        isLoading={isLoading}
        {...rest}
      />
      {error && (
        <span style={{ color: '#f00', marginTop: 5, fontWeight: 500 }}>
          {error}
        </span>
      )}
    </SelectContainer>
  );
};

InputSelect.propTypes = propTypes;
InputSelect.defaultProps = defaultProps;

export default InputSelect;
