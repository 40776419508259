import styled from 'styled-components';

const TabsContainer = styled.div`
  margin-top: 2rem;
`;

const Tab = styled.li`
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  color: ${({ active }) =>
    active ? 'var(--primary-color)' : 'var(--font-color)'};
  border-bottom: ${({ active }) =>
    active ? '2px solid var(--primary-color)' : 'none'};
`;

export { TabsContainer, Tab };
