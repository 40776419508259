import { useEffect, useState } from 'react';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import Table from '../../components/Table';

import { Container } from './styles';
import DeleteModal from '../../components/AlertModal';
import PageHeader from '../../components/PageHeader';
import TermsModal from '../../components/TermsModal';

const itemLabels = {
  id: { label: 'ID' },
  description: { label: 'Descrição' },
};

const Terms = () => {
  const { loading } = useAuth();
  const [terms, setTerms] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState({});

  async function fetchTerms() {
    if (!loading) {
      try {
        const { data } = await api.get('/admin/terms');
        setTerms(data);
      } catch (err) {
        const error = err.response
          ? err.response.data.error
          : 'Ocorreu um erro ao buscar termos';

        Notification({ message: error, type: Notification.types.ERROR });
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (!loading) {
      fetchTerms();
    }
  }, [loading]);

  function handleDeleteButtonClick(user) {
    setSelectedTerm(user);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
    setTimeout(() => selectedTerm({}), DeleteModal.closeTimeout);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/term/${selectedTerm.id}`);
      fetchTerms();
      Notification({
        message: 'Termo excluído com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao excluir termo';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  function handleAddButtonClick() {
    setAddModalOpen(true);
  }

  function handleCloseAddModal() {
    setAddModalOpen(false);
  }

  async function handleAddTerm(data) {
    try {
      setAddModalOpen(false);
      setLoading(true);
      await api.post(`/admin/terms`, data);
      fetchTerms();
      Notification({
        message: 'Termo adicionado com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao adicionar termo';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
      setSelectedTerm({});
    }
  }

  function handleEditButtonClick(term) {
    setSelectedTerm(term);
    setEditModalOpen(true);
  }

  function handleCloseEditModal() {
    setEditModalOpen(false);
  }

  async function handleEditTerm(data) {
    try {
      setEditModalOpen(false);
      setLoading(true);
      await api.put(`/admin/term/${selectedTerm.id}`, data);
      fetchTerms();
      Notification({
        message: 'Termo editado com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao editar termo';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
      setSelectedTerm({});
    }
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title="Excluir termo"
        text={`Deseja realmente excluir o termo ${selectedTerm.description}?`}
        type="delete"
      />
      <TermsModal
        title="Adicionar termo"
        text="Digite as informações do termo "
        isOpen={addModalOpen}
        onConfirm={handleAddTerm}
        onClose={handleCloseAddModal}
        defaultValue={selectedTerm}
      />
      <TermsModal
        title="Editar termo"
        text="Digite o novo nome do termo"
        isOpen={editModalOpen}
        onConfirm={handleEditTerm}
        onClose={handleCloseEditModal}
        defaultValue={selectedTerm}
      />
      <Container>
        <PageHeader
          title="Termos"
          buttonText="Adicionar novo termo"
          onAddButtonClick={handleAddButtonClick}
        />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={terms}
            headers={itemLabels}
            onDelete={handleDeleteButtonClick}
            onEdit={handleEditButtonClick}
          />
        )}
      </Container>
    </>
  );
};

export default Terms;
