import { Link } from 'react-router-dom';
import {
  FiUser,
  FiShoppingBag,
  FiLayers,
  FiDollarSign,
  FiLogOut,
  FiCodesandbox,
  FiGift,
  FiSettings,
  FiFlag,
  FiDownload,
  FiFileText,
  FiKey,
} from 'react-icons/fi';

import LogoSvg from '../../assets/svg/logo.svg';
import { useAuth } from '../../hooks/auth';

import { Container, MenuItem, Logo } from './styles';

const DesignerSideMenu = () => {
  const { signOut } = useAuth();

  return (
    <Container>
      <Logo src={LogoSvg} alt="" />
      <ul>
        <MenuItem>
          <FiLayers color="#00ADEF" size={20} />
          <Link to="/projects">Projetos</Link>
        </MenuItem>
        <MenuItem>
          <FiDollarSign color="#00ADEF" size={20} />
          <Link to="/payments">Pagamentos</Link>
        </MenuItem>
        <MenuItem>
          <FiUser color="#00ADEF" size={20} />
          <Link to="/users">Usuários</Link>
        </MenuItem>
        <MenuItem>
          <FiCodesandbox color="#00ADEF" size={20} />
          <Link to="/categories">Categorias</Link>
        </MenuItem>
        <MenuItem>
          <FiShoppingBag color="#00ADEF" size={20} />
          <Link to="/materials">Materiais</Link>
        </MenuItem>
        <MenuItem>
          <FiGift color="#00ADEF" size={20} />
          <Link to="/vouchers">Vouchers</Link>
        </MenuItem>
        <MenuItem>
          <FiDownload color="#00ADEF" size={20} />
          <Link to="/withdrawals">Pedidos de saque</Link>
        </MenuItem>
        <MenuItem>
          <FiFlag color="#00ADEF" size={20} />
          <Link to="/reports">Denúncias</Link>
        </MenuItem>
        <MenuItem>
          <FiFileText color="#00ADEF" size={20} />
          <Link to="/terms">Termos</Link>
        </MenuItem>
        <MenuItem>
          <FiKey color="#00ADEF" size={20} />
          <Link to="/indications">Indicações</Link>
        </MenuItem>
        <MenuItem>
          <FiSettings color="#00ADEF" size={20} />
          <Link to="/configs">Configurações</Link>
        </MenuItem>
        <MenuItem>
          <FiLogOut color="#00ADEF" size={20} />
          <Link to="/" onClick={signOut}>
            Sair
          </Link>
        </MenuItem>
      </ul>
    </Container>
  );
};

export default DesignerSideMenu;
