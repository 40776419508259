import { useRef, useState } from 'react';
import { Form } from '@unform/web';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Notification from '../../components/Notification';
import Input from '../../components/Input';
import Button from '../../components/Button';
import LoginBackground from '../../assets/images/login-background.png';
import LogoSvg from '../../assets/svg/logo.svg';

import { Container, Image, BlueEllipse, LoginContainer, Logo } from './styles';

const Login = () => {
  const formRef = useRef();
  const { signIn } = useAuth();
  const [isLoading, setLoading] = useState(false);

  async function handleFormSubmit({ email, password }) {
    formRef.current.setErrors({});
    if (email === '') {
      formRef.current.setErrors({ email: 'Informe um email' });
      return;
    }
    if (password === '') {
      formRef.current.setErrors({ password: 'Informe uma senha' });
      return;
    }

    try {
      setLoading(true);
      const { data } = await api.post('/auth', { email, password });

      if (data.userType.toLowerCase() !== 'admin') {
        Notification({
          message: 'Acesso negado.',
          type: Notification.types.ERROR,
        });
        return;
      }

      signIn({
        newToken: data.token,
        newUserImage: data.userImage || '',
        newUserName: data.userName,
      });
    } catch (err) {
      const error = err.response ? err.response.data.error : 'Acesso negado';
      Notification({
        message: error,
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <LoginContainer>
          <Logo src={LogoSvg} alt="Logo" />
          <Form ref={formRef} onSubmit={handleFormSubmit}>
            <Input type="email" name="email" label="E-mail" />
            <Input type="password" name="password" label="Senha" />
            <Button primary text="Acessar conta" type="submit" />
          </Form>
        </LoginContainer>
        <BlueEllipse />
        <Image src={LoginBackground} />
      </Container>
    </>
  );
};

export default Login;
