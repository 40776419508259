import { useEffect, useState } from 'react';

import api from '../../services/api';
import { SortTypes } from '../../hooks/sortableData';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import Table from '../../components/Table';
import Tabs from '../../components/Tabs';
import { Container } from './styles';
import DeleteModal from '../../components/AlertModal';
import PageHeader from '../../components/PageHeader';

const cancelledPaymentsLabels = {
  paymentDate: { label: 'Pagamento em', dataType: SortTypes.DATE },
  canceledAt: { label: 'Cancelado em', dataType: SortTypes.DATE },
  amount: { label: 'Valor', dataType: SortTypes.MONEY },
  description: { label: 'Descrição' },
  payee: { label: 'Beneficiário' },
};

const pendingPaymentsLabels = {
  paymentDate: { label: 'Pagamento em', dataType: SortTypes.DATE },
  amount: { label: 'Valor', dataType: SortTypes.MONEY },
  description: { label: 'Descrição' },
  payee: { label: 'Beneficiário' },
};

const finalizedPaymentsLabels = {
  paymentDate: { label: 'Pagamento em', dataType: SortTypes.DATE },
  paidAt: { label: 'Pago em', dataType: SortTypes.DATE },
  amount: { label: 'Valor', dataType: SortTypes.MONEY },
  description: { label: 'Descrição' },
  payee: { label: 'Beneficiário' },
};

const Payments = () => {
  const { loading } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});

  const [canceledPayments, setCanceledPayments] = useState([]);
  const [canceledPaymentsLoading, setCanceledPaymentsLoading] = useState(true);

  const [pendingPayments, setPendingPayments] = useState([]);
  const [pendingPaymentsLoading, setPendingPaymentsLoading] = useState(true);

  const [finalizedPayments, setFinalizedPayments] = useState([]);
  const [finalizedPaymentsLoading, setFinalizedPaymentsLoading] =
    useState(true);

  async function fetchPendingPayments() {
    try {
      const { data } = await api.get('/admin/pending_payments');
      setPendingPayments(data);
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao buscar pagamentos';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setPendingPaymentsLoading(false);
    }
  }

  async function fetchFinalizedPayments() {
    try {
      const { data } = await api.get('/admin/finalized_payments');
      setFinalizedPayments(data);
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao buscar pagamentos';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setFinalizedPaymentsLoading(false);
    }
  }

  async function fetchCanceledPayments() {
    try {
      const { data } = await api.get('/admin/canceled_payments');
      setCanceledPayments(data);
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao buscar pagamentos';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setCanceledPaymentsLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) {
      fetchFinalizedPayments();
      fetchPendingPayments();
      fetchCanceledPayments();
    }
  }, [loading]);

  function handleDeleteButtonClick(payment) {
    setSelectedPayment(payment);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
    setTimeout(() => setSelectedPayment({}), DeleteModal.closeTimeout);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/payment/${selectedPayment.id}`);
      fetchPendingPayments();
      fetchCanceledPayments();
      Notification({
        message: 'Pagamento cancelado com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao cancelar pagamento';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title="Cancelar pagamento"
        text={`Deseja realmente cancelar o pagamento de R$${
          selectedPayment.amount
        } agendado para o dia ${new Date(
          selectedPayment.paymentDate
        ).toLocaleDateString('pt-BR')} para o usuário ${
          selectedPayment.payee
        }?`}
        type="delete"
      />
      <Container>
        {isLoading && <div className="loader" />}
        <PageHeader title="Pagamentos" showAddButton={false} />
        <Tabs>
          <div label="Pagamentos Pendentes">
            {pendingPaymentsLoading ? (
              <div className="loader" />
            ) : (
              <Table
                items={pendingPayments}
                headers={pendingPaymentsLabels}
                onDelete={handleDeleteButtonClick}
                showEdit={false}
              />
            )}
          </div>
          <div label="Pagamentos Feitos">
            {finalizedPaymentsLoading ? (
              <div className="loader" />
            ) : (
              <Table
                items={finalizedPayments}
                headers={finalizedPaymentsLabels}
                showDelete={false}
                showEdit={false}
              />
            )}
          </div>
          <div label="Pagamentos Cancelados">
            {canceledPaymentsLoading ? (
              <div className="loader" />
            ) : (
              <Table
                items={canceledPayments}
                headers={cancelledPaymentsLabels}
                showDelete={false}
                showEdit={false}
              />
            )}
          </div>
        </Tabs>
      </Container>
    </>
  );
};

export default Payments;
