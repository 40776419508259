import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;

  td {
    padding: 2rem 0;
  }

  td,
  th {
    border-bottom: 1px solid #e2e2e2;
  }

  th {
    user-select: none;
  }

  td svg {
    margin-left: 15px;

    :hover {
      opacity: 0.6;
    }
  }

  tr td:last-child {
    text-align: end;

    svg {
      cursor: pointer;
    }
  }

  .arrow {
    border: solid var(--primary-color);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  color: var(--primary-color);
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }

  .arrow {
    display: ${({ sorted }) => (sorted ? 'inline-block' : 'none')};
    transform: ${({ sorted }) =>
      sorted && sorted.direction === 'DESC'
        ? 'rotate(-135deg) translate(-2px, 0px)'
        : 'rotate(45deg) translate(-2px, -2px)'};
    margin-left: 0.8rem;
  }
`;

export { Table, TableHeader };
