import styled from 'styled-components';

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  grid-area: menu;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 4rem;
  > h1 {
    font-size: 2.4rem;
  }
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  margin: 2.8rem 0;
  transition: opacity 0.5s;
  white-space: nowrap;

  :hover {
    opacity: 0.6;
    transition: opacity 0.2s;
  }

  a {
    text-decoration: none;
    font-weight: 700;
    margin-left: 2rem;
  }

  svg {
    margin-bottom: 3px;
  }
`;

const Logo = styled.img`
  width: 10rem;
  margin-left: 1rem;
  margin-bottom: 3rem;
`;

export { Container, MenuItem, Logo };
