import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { SortTypes } from '../../hooks/sortableData';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import Table from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteModal from '../../components/AlertModal';

import { Container } from './styles';

const itemLabels = {
  name: { label: 'Nome' },
  situation: { label: 'Situação' },
  designerName: { label: 'Autor' },
  price: { label: 'Preço', dataType: SortTypes.MONEY },
  createdAt: { label: 'Criado em', dataType: SortTypes.DATE },
  premium: { label: 'Premium' },
  featured: { label: 'Destaque' },
};

const Projects = () => {
  const history = useHistory();
  const { loading } = useAuth();
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});

  async function fetchProjects() {
    (async () => {
      try {
        const { data } = await api.get('/admin/projects');
        setProjects(
          data.map(p => ({
            ...p,
            designerName: p.designerUser.name,
            premium: p.premium ? 'Sim' : 'Não',
            featured: p.featured ? 'Sim' : 'Não',
          }))
        );
      } catch (err) {
        const error = err.response
          ? err.response.data.error
          : 'Ocorreu um erro ao buscar projetos';

        Notification({ message: error, type: Notification.types.ERROR });
      } finally {
        setLoading(false);
      }
    })();
  }

  useEffect(() => {
    if (!loading) {
      fetchProjects();
    }
  }, [loading]);

  function handleDeleteButtonClick(project) {
    setSelectedProject(project);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
    setTimeout(() => setSelectedProject({}), DeleteModal.closeTimeout);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/project/${selectedProject.id}`);
      fetchProjects();
      Notification({
        message: 'Projeto excluido com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao excluir projeto';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  function handleEditButtonClick(project) {
    history.push(`/project/${project.id}/edit`);
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title={`Excluir ${selectedProject.name}`}
        text={`Deseja realmente excluir o projeto ${selectedProject.name}?`}
        type="delete"
      />
      <Container>
        <PageHeader title="Projetos" showAddButton={false} />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={projects}
            headers={itemLabels}
            onDelete={handleDeleteButtonClick}
            onEdit={handleEditButtonClick}
          />
        )}
      </Container>
    </>
  );
};

export default Projects;
