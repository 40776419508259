import styled from 'styled-components';

const CustomInput = styled.input`
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  width: 100%;
`;

const CustomTextArea = styled.textarea`
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding-top: 14px;
`;

const Label = styled.label`
  background-color: white;
  transform: translateY(8px);
  align-self: flex-start;
  padding: 0 10px 0 8px;
  margin-left: 10px;
  color: var(--primary-color);
  font-weight: 500;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  :focus-within {
    input,
    textarea {
      border-color: var(--accent-color);
    }

    label {
      color: var(--accent-color);
    }
  }
`;

export { CustomInput, Label, InputContainer, CustomTextArea };
