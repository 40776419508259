import styled from 'styled-components';

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .react-select-container,
  .react-select__value-container,
  .react-select__control {
    height: var(--input-height);
  }

  .react-select__value-container {
    padding-left: 15px;
  }

  .react-select__control {
    border: 1px solid var(--primary-color);
    border-radius: 8px;
  }

  .react-select__multi-value {
    font-size: 1.6rem;
    padding: 0.2rem 0.5rem;
    background-color: rgba(243, 146, 00, 0.12);
  }

  .react-select__multi-value,
  .react-select__multi-value__label {
    color: var(--accent-color);
  }

  .react-select__multi-value:not(:first-child) {
    margin-left: 0.7rem;
  }

  .react-select__control--is-focused {
    box-shadow: none;
    border: 1px solid var(--accent-color) !important;
  }

  .react-select__menu {
    z-index: 2;
  }

  :focus-within {
    > label {
      color: var(--accent-color);
    }
  }

  .react-select__placeholder {
    margin-left: 8px;
  }

  .react-select__placeholder,
  .react-select__single-value {
    margin-top: 5px;
  }

  .react-select__input {
    margin-left: 8px;
  }
`;

const Label = styled.label`
  background-color: white;
  transform: translateY(10px);
  align-self: flex-start;
  padding: 0 10px 0 8px;
  margin-left: 10px;
  color: var(--primary-color);
  font-weight: 500;
  z-index: 1;
`;

export { SelectContainer, Label };
