import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;

  form p {
    font-weight: 700;
    margin-top: 2rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  div:not(:last-child) {
    margin-right: 2rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2.5rem;
`;

export { Container, InputContainer, ButtonsContainer };
