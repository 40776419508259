import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'menu header'
    'menu main';
  grid-template-rows: 9rem 1fr;
  grid-template-columns: 25rem 1fr;
`;

const Main = styled.main`
  display: flex;
  grid-area: main;
  padding: 4rem 5rem;
`;

export { Container, Main };
