import { useEffect, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import ReportInfoModal from '../../components/ReportInfoModal';
import Notification from '../../components/Notification';
import { SortTypes } from '../../hooks/sortableData';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import { Container } from './styles';
import DeleteModal from '../../components/AlertModal';

const itemLabels = {
  name: { label: 'Nome' },
  email: { label: 'Email' },
  date: { label: 'Feito em', dataType: SortTypes.DATE },
  projectName: { label: 'Nome do projeto' },
};

const Reports = () => {
  const { loading } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});

  async function fetchReports() {
    try {
      const response = await api.get('/admin/reports');
      const mReports = response.data;
      mReports.forEach(r => {
        if (r.project) {
          r.projectName = r.project.name;
        }
      });
      setReports(mReports);
    } catch (err) {
      Notification({
        message: 'Erro ao buscar denúncias',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (!loading) {
      fetchReports();
    }
  }, [loading]);

  async function handleDeleteModalConfirm() {
    try {
      setLoading(true);
      await api.delete(`/admin/report/${selectedReport.id}`);
      fetchReports();
    } catch (err) {
      Notification({
        message: 'Erro ao excluir denúncia',
        type: Notification.types.ERROR,
      });
    } finally {
      setShowDeleteModal(false);
      setLoading(false);
    }
  }

  function handleEyeButtonClick(r) {
    setSelectedReport(r);
    setShowInfoModal(true);
  }

  function handleDeleteButtonClick(r) {
    setSelectedReport(r);
    setShowDeleteModal(true);
  }

  function handleDeleteModalClose() {
    setShowDeleteModal(false);
  }

  function handleInfoModalClose() {
    setShowInfoModal(false);
  }

  return (
    <>
      <ReportInfoModal
        onClose={handleInfoModalClose}
        isOpen={showInfoModal}
        report={selectedReport}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title="Excluir denúncia"
        text="Deseja realmente excluir esta denúncia?"
        type="delete"
      />

      <Container>
        <PageHeader title="Denúncias" showAddButton={false} />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={reports}
            headers={itemLabels}
            onDelete={handleDeleteButtonClick}
            showEdit={false}
            showEye
            onEye={handleEyeButtonClick}
          />
        )}
      </Container>
    </>
  );
};

export default Reports;
