import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import Button from '../Button';
import Notification from '../Notification';

import { Headline, ButtonsContainer, Table, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  voucherId: PropTypes.number.isRequired,
};

const VoucherUsersModal = ({ isOpen, onClose, voucherId }) => {
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (isOpen === true) {
      (async () => {
        try {
          setLoading(true);
          const response = await api.get(`/admin/voucher/${voucherId}/users`);
          setUsers(response.data);
        } catch (err) {
          if (err.response) {
            Notification({
              message: err.response.data.error,
              type: Notification.types.ERROR,
            });
          } else {
            Notification({
              message: 'Erro ao buscar usuários',
              type: Notification.types.ERROR,
            });
          }
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Usuários do voucher"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      {isLoading && <div className="loader" />}
      <Headline>Usuários do voucher</Headline>
      <Table>
        <tr>
          <th>Nome</th>
          <th>CPF</th>
          <th>Email</th>
        </tr>
        {users.length > 0 &&
          users.map(user => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.cpf}</td>
              <td>{user.email}</td>
            </tr>
          ))}
      </Table>
      <ButtonsContainer>
        <Button text="Fechar" onClick={onClose} />
      </ButtonsContainer>
    </Modal>
  );
};

VoucherUsersModal.propTypes = propTypes;

export default VoucherUsersModal;
