import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';

import { Headline, Text, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const InfoModal = ({ isOpen, onClose, title, text }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel={title}
    style={modalStyles}
    closeTimeoutMS={500}
  >
    <Headline>{title}</Headline>
    <Text>{text}</Text>
    <Button text="Fechar" onClick={onClose} />
  </Modal>
);

InfoModal.propTypes = propTypes;

export default InfoModal;
