import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';

import { Headline, ButtonsContainer, Container, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  report: PropTypes.object.isRequired,
};

const ReportInfoModal = ({ isOpen, onClose, report }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Informações da denúncia"
    style={modalStyles}
    closeTimeoutMS={500}
  >
    <Headline>Informações da denúncia</Headline>
    <Container>
      <h3>Dados do formulário</h3>
      <p>
        <strong>Nome:</strong>
        {` ${report.name}`}
      </p>
      {report.email && (
        <p>
          <strong>Email:</strong>
          {` ${report.email}`}
        </p>
      )}
      {report.phone && (
        <p>
          <strong>Telefone:</strong>
          {` ${report.phone}`}
        </p>
      )}
      <p>
        <strong>Mensagem:</strong>
        {` ${report.message}`}
      </p>

      {report.user && (
        <>
          <h3>Usuário logado no momento da denúncia:</h3>
          <p>
            <strong>Nome:</strong>
            {` ${report.user.name}`}
          </p>
          <p>
            <strong>Email:</strong>
            {` ${report.user.email}`}
          </p>
        </>
      )}

      <h3>Dados do projeto</h3>
      <p>
        <strong>ID do projeto:</strong>
        {` ${report.project?.id}`}
      </p>
      <p>
        <strong>Nome:</strong>
        {` ${report.project?.name}`}
      </p>
    </Container>

    <ButtonsContainer>
      <Button text="Fechar" onClick={onClose} />
    </ButtonsContainer>
  </Modal>
);

ReportInfoModal.propTypes = propTypes;

export default ReportInfoModal;
