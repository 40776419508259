import Modal from 'react-modal';
import { useRef, useState } from 'react';
import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import api from '../../services/api';
import Button from '../Button';
import Input from '../Input';
import Notification from '../Notification';

import { Headline, ButtonsContainer, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.object.isRequired,
};

const EditConfigModal = ({ isOpen, onClose, onConfirm, initialData }) => {
  const formRef = useRef(null);

  const [isLoading, setLoading] = useState(false);

  async function handleSubmitForm(data) {
    try {
      setLoading(true);
      await api.put(`/admin/config/${initialData.id}`, data);
      Notification({
        message: 'Configuração editado com sucesso',
        type: Notification.types.SUCCESS,
      });
      onConfirm();
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
        if (err.response.data.errors) {
          formRef.current.setErrors(err.response.data.errors);
        }
      } else {
        Notification({
          message: 'Erro ao editar configuração',
          type: Notification.types.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Editar configuração"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      {isLoading && <div className="loader" />}
      <Headline>Editar configuração</Headline>
      <Form onSubmit={handleSubmitForm} ref={formRef} initialData={initialData}>
        <Input
          type="text"
          label="Descrição da configuração"
          name="description"
          multiline
        />
        <Input type="number" label="Valor da configuração" name="value" />
        <ButtonsContainer>
          <Button text="Cancelar" onClick={onClose} />
          <Button text="Confirmar" primary type="submit" />
        </ButtonsContainer>
      </Form>
    </Modal>
  );
};

EditConfigModal.propTypes = propTypes;

export default EditConfigModal;
