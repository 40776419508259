import { useRef } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';

import Button from '../Button';
import Input from '../Input';

import { Headline, ButtonsContainer, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    description: PropTypes.string,
    text: PropTypes.string,
  }),
};

const defaultProps = {
  defaultValue: {
    description: '',
    text: '',
  },
};

const modalCloseTimeout = 500;

const TermsModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  text,
  defaultValue,
}) => {
  const formRef = useRef(null);

  function handleSubmit(data) {
    if (!data.description || !data.text) return;
    onConfirm(data);
    setTimeout(() => {
      formRef.current.reset();
    }, modalCloseTimeout);
  }

  function handleCancelButtonClick() {
    onClose();
    setTimeout(() => {
      formRef.current.reset();
    }, modalCloseTimeout);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      style={modalStyles}
      closeTimeoutMS={modalCloseTimeout}
    >
      <Headline>{title}</Headline>
      <p>{text}</p>
      <Form initialData={defaultValue} ref={formRef} onSubmit={handleSubmit}>
        <Input autoFocus name="description" label="Descrição" />
        <Input name="text" label="Texto" multiline />
        <ButtonsContainer>
          <Button text="Cancelar" onClick={handleCancelButtonClick} />
          <Button text="Confirmar" primary type="submit" />
        </ButtonsContainer>
      </Form>
    </Modal>
  );
};

TermsModal.propTypes = propTypes;
TermsModal.defaultProps = defaultProps;

export default TermsModal;
