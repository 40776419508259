import { NotificationManager } from 'react-notifications';

import 'react-notifications/lib/notifications.css';
import './styles.css';

const Notification = ({ type, message, title = '', timeout = 3000 }) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message, title, timeout);
      break;
    case 'success':
      NotificationManager.success(message, 'Sucesso', timeout);
      break;
    case 'warning':
      NotificationManager.warning(message, title, timeout);
      break;
    case 'error':
      NotificationManager.error(message, 'Erro', 5000);
      break;

    default:
      NotificationManager.info(message);
  }
};

Notification.types = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

export default Notification;
