import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { CustomInput, Label, InputContainer } from './styles';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
};

const defaultProps = {
  multiline: false,
};

const InputCheckBox = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: ref => ref.checked,
      setValue: (ref, value) => {
        ref.checked = value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <InputContainer>
        <CustomInput
          ref={inputRef}
          id={fieldName}
          type="checkbox"
          defaultValue={!!rest.value}
          {...rest}
        />
        {label && <Label htmlFor={fieldName}>{label}</Label>}
      </InputContainer>
      {error && (
        <span style={{ color: '#f00', marginTop: 5, fontWeight: 500 }}>
          {error}
        </span>
      )}
    </>
  );
};

InputCheckBox.propTypes = propTypes;
InputCheckBox.defaultProps = defaultProps;

export default InputCheckBox;
