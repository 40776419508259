import styled from 'styled-components';
import Scroll from 'react-indiana-drag-scroll';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;

  form {
    margin-top: 2rem;

    > button:last-child {
      margin-top: 2rem;
      margin-left: auto;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-right: 2rem;
  }

  button {
    margin-top: 18px;

    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const AddFileInput = styled.input`
  position: absolute;
  visibility: hidden;
`;

const AddImageButton = styled.button`
  border: 1px dashed #c0c0c0;
  height: 12rem;
  min-width: 12rem;
  width: 12rem;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background-color: transparent;

  :hover {
    border: 1px dashed var(--primary-color);
  }
`;

const Image = styled.img`
  height: 12rem;
  width: 12rem;
  object-fit: cover;
  border-radius: 8px;
  margin-left: 1rem;
`;

const ImageContainer = styled.div`
  display: inline-block;
  vertical-align: center;
  margin-right: -2rem;

  svg {
    transform: translate(-2.5rem, -9.5rem);

    :hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

const ScrollContainer = styled(Scroll)`
  margin-top: 2rem;
  width: 100%;
  white-space: nowrap;
`;

export {
  Container,
  InputContainer,
  AddFileInput,
  AddImageButton,
  Image,
  ImageContainer,
  ScrollContainer,
};
