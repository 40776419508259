import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  Info,
  ImageContainer,
  InfoContainer,
  EditContainer,
  Header,
} from './styles';

const Profile = () => {
  const { loading } = useAuth();
  const history = useHistory();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!loading) {
      (async () => {
        const { data } = await api.get('/user');
        setUser(data);
      })();
    }
  }, [loading]);

  return (
    <Container>
      <Header>
        <div>
          <h2>Minha conta</h2>
          <p>Dados pessoais</p>
        </div>
        <EditContainer onClick={() => history.push('/profile/edit')}>
          <p>Editar Perfil</p>
          <FiEdit size={20} color="#00ADEF" />
        </EditContainer>
      </Header>
      <InfoContainer>
        <ImageContainer>
          {user.image ? (
            <img src={user.image.url} alt="" />
          ) : (
            <FaUserCircle color="#9a9a9a" size={150} />
          )}
        </ImageContainer>
        <Info>
          <div>
            <div>
              <small>Nome</small>
              <p>{user.name}</p>
            </div>
            <div>
              <small>E-mail</small>
              <p>{user.email}</p>
            </div>
          </div>
          <div>
            <div>
              <small>Senha</small>
              <p>***********</p>
            </div>
          </div>
        </Info>
      </InfoContainer>
    </Container>
  );
};

export default Profile;
