import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';

import Button from '../Button';

import { Headline, ButtonsContainer, Content, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['delete', 'confirm']),
};

const defaultProps = {
  type: 'confirm',
};

const modalCloseTimeout = 500;

const AlertModal = ({ isOpen, onClose, onConfirm, title, text, type }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={modalStyles}
    closeTimeoutMS={modalCloseTimeout}
  >
    <Content>
      {type === 'confirm' ? (
        <FiAlertCircle color="#F39200" size={100} />
      ) : (
        <FiAlertTriangle color="red" size={100} />
      )}
      <Headline>{title}</Headline>
      <p>{text}</p>
    </Content>
    <ButtonsContainer>
      <Button text="Cancelar" warning={type === 'delete'} onClick={onClose} />
      <Button
        text="Confirmar"
        warning={type === 'delete'}
        primary
        onClick={onConfirm}
      />
    </ButtonsContainer>
  </Modal>
);

AlertModal.propTypes = propTypes;
AlertModal.defaultProps = defaultProps;

AlertModal.closeTimeout = modalCloseTimeout;

export default AlertModal;
