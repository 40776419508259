import { FiSearch } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { Container, InputContainer, UserInfo, UserImage } from './styles';
import { useAuth } from '../../hooks/auth';

const Header = () => {
  const history = useHistory();
  const { userImage, userName } = useAuth();

  function handleUserInfoClick() {
    history.push('/profile');
  }

  return (
    <Container>
      <InputContainer>
        <input disabled type="text" placeholder="O que você está procurando?" />
        <FiSearch color="#00adef" size={24} />
      </InputContainer>
      <UserInfo title="Perfil" onClick={handleUserInfoClick}>
        {userImage ? (
          <UserImage src={userImage} alt="" />
        ) : (
          <FaUserCircle size={34} color="#9a9a9a" />
        )}
        <p>{userName}</p>
      </UserInfo>
    </Container>
  );
};

export default Header;
