import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import Table from '../../components/Table';
import DeleteModal from '../../components/AlertModal';
import PageHeader from '../../components/PageHeader';

import { Container } from './styles';

const itemLabels = {
  name: { label: 'Nome' },
  email: { label: 'E-mail' },
  cpf: { label: 'CPF' },
  verified: { label: 'Verificado' },
  type: { label: 'Tipo de usuário' },
  designerPremium: { label: 'Designer Premium' },
};

const Materials = () => {
  const history = useHistory();
  const { loading } = useAuth();
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  async function fetchUsers() {
    (async () => {
      try {
        const { data } = await api.get('/admin/users');
        setUsers(
          data.map(u => ({
            ...u,
            verified: u.verified ? 'Sim' : 'Não',
            designerPremium: u.designerPremium ? 'Sim' : 'Não',
          }))
        );
      } catch (err) {
        const error = err.response
          ? err.response.data.error
          : 'Ocorreu um erro ao buscar usuários';

        Notification({ message: error, type: Notification.types.ERROR });
      } finally {
        setLoading(false);
      }
    })();
  }

  useEffect(() => {
    if (!loading) {
      fetchUsers();
    }
  }, [loading]);

  function handleDeleteButtonClick(user) {
    setSelectedUser(user);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
    setTimeout(() => setSelectedUser({}), DeleteModal.closeTimeout);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/user/${selectedUser.id}`);
      fetchUsers();
      Notification({
        message: 'Usuário excluído com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao excluir usuário';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  function handleAddButtonClick() {
    history.push('/users/add');
  }

  function handleEditButtonClick(user) {
    history.push(`/user/${user.id}/edit`);
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title={`Excluir ${selectedUser.name}`}
        text={`Deseja realmente excluir o usuário ${selectedUser.name}?`}
        type="delete"
      />
      <Container>
        <PageHeader
          title="Usuários"
          buttonText="Adicionar novo usuário"
          onAddButtonClick={handleAddButtonClick}
        />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={users}
            headers={itemLabels}
            onDelete={handleDeleteButtonClick}
            onEdit={handleEditButtonClick}
          />
        )}
      </Container>
    </>
  );
};

export default Materials;
