import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';

import AppProvider from './hooks';
import Routes from './routes';
import GlobalStyles from './styles/global';

// Accessibility reasons
Modal.setAppElement('#root');

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <GlobalStyles />
        <Routes />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
