import { useEffect, useState } from 'react';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import Table from '../../components/Table';
import DeleteModal from '../../components/AlertModal';
import PageHeader from '../../components/PageHeader';

import { Container } from './styles';
import ModalWithTwoInput from '../../components/ModalWithTwoInput';

const itemLabels = {
  name: { label: 'Categoria' },
};

const Categories = () => {
  const { loading } = useAuth();
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  async function fetchCategories() {
    if (!loading) {
      (async () => {
        try {
          const { data } = await api.get('/project_categories');
          setCategories(data);
        } catch (err) {
          const error = err.response
            ? err.response.data.error
            : 'Ocorreu um erro ao buscar categorias';

          Notification({ message: error, type: Notification.types.ERROR });
        } finally {
          setLoading(false);
        }
      })();
    }
  }

  useEffect(() => {
    fetchCategories();
  }, [loading]);

  function handleDeleteButtonClick(user) {
    setSelectedCategory(user);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
    setTimeout(() => setSelectedCategory({}), DeleteModal.closeTimeout);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/project_category/${selectedCategory.id}`);
      fetchCategories();
      Notification({
        message: 'Categoria excluída com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao excluir categoria';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
      setSelectedCategory({});
    }
  }

  function handleAddButtonClick() {
    setAddModalOpen(true);
  }

  function handleCloseAddModal() {
    setAddModalOpen(false);
  }

  async function handleAddCategory(data) {
    try {
      setAddModalOpen(false);
      setLoading(true);
      await api.post(`/admin/project_categories`, data);
      fetchCategories();
      Notification({
        message: 'Categoria adicionada com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao adicionar categoria';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
      setSelectedCategory({});
    }
  }

  function handleEditButtonClick(category) {
    setSelectedCategory(category);
    setEditModalOpen(true);
  }

  function handleCloseEditModal() {
    setEditModalOpen(false);
  }

  async function handleEditCategory(data) {
    try {
      setEditModalOpen(false);
      setLoading(true);
      await api.put(`/admin/project_category/${selectedCategory.id}`, data);
      fetchCategories();
      Notification({
        message: 'Categoria editada com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao editar categoria';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
      setSelectedCategory({});
    }
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title={`Excluir ${selectedCategory.name}`}
        text={`Deseja realmente excluir a categoria ${selectedCategory.name}?`}
        type="delete"
      />
      <ModalWithTwoInput
        title="Adicionar categoria"
        text="Digite as informações da categoria que você deseja adicionar"
        isOpen={addModalOpen}
        onConfirm={handleAddCategory}
        onClose={handleCloseAddModal}
        defaultValue={selectedCategory}
      />
      <ModalWithTwoInput
        title="Editar categoria"
        text="Digite as novas informações da categoria"
        isOpen={editModalOpen}
        onConfirm={handleEditCategory}
        onClose={handleCloseEditModal}
        defaultValue={selectedCategory}
      />
      <Container>
        <PageHeader
          title="Categorias"
          buttonText="Adicionar nova categoria"
          onAddButtonClick={handleAddButtonClick}
        />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={categories}
            headers={itemLabels}
            onDelete={handleDeleteButtonClick}
            onEdit={handleEditButtonClick}
          />
        )}
      </Container>
    </>
  );
};

export default Categories;
