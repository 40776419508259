import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoMdCloseCircle } from 'react-icons/io';

import Input from '../../components/Input';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import InputCheckBox from '../../components/InputCheckBox';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import {
  Container,
  InputContainer,
  AddFileInput,
  AddImageButton,
  Image,
  ImageContainer,
  ScrollContainer,
} from './styles';

const ProjectSituations = [
  {
    label: 'Aprovado',
    value: 'Aprovado',
  },
  {
    label: 'Verificar',
    value: 'Verificar',
  },
  {
    label: 'Em análise',
    value: 'Em análise',
  },
];

const EditProject = () => {
  const { id } = useParams();
  const { loading } = useAuth();
  const inputUploadImageRef = useRef(null);
  const inputUploadProjectFileRef = useRef(null);
  const inputUploadProjectPdfRef = useRef(null);
  const formRef = useRef(null);

  const [isLoading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [addedImages, setAddedImages] = useState([]);
  const [excludedImages, setExcludedImages] = useState([]);

  const [file, setFile] = useState('');
  const [isNewFile, setNewFile] = useState(false);
  const [pdf, setPdf] = useState('');
  const [isNewPdf, setNewPdf] = useState(false);

  const [materialsOptions, setMaterialsOptions] = useState('');
  const [categoriesOptions, setCategoriesOptions] = useState('');
  const [projectSituation, setProjectSituation] = useState('');
  const [sendMail, setSendMail] = useState(false);

  function formatToSelectOption(arr) {
    return arr.map(i => ({ label: i.name, value: i.id }));
  }

  useEffect(() => {
    if (loading) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/admin/project/${id}`);
        formRef.current.setData({
          ...data,
          categories: formatToSelectOption(data.categories),
          materials: formatToSelectOption(data.materials),
          situation: { label: data.situation, value: data.situation },
        });
        // This field is not filled when setData() is called
        formRef.current.setFieldValue('reproofReason', data.reproofReason);
        setImages(data.images);
        setFile(data.projectFile || '');
        setPdf(data.projectPdf || '');
      } catch (err) {
        const error = err.response
          ? err.response.data.error
          : 'Erro ao buscar dados do projeto';

        Notification({
          type: Notification.types.ERROR,
          message: error,
        });
      } finally {
        setLoading(false);
      }
    })();

    (async () => {
      const { data } = await api.get('/project_categories');
      setCategoriesOptions(formatToSelectOption(data));
    })();

    (async () => {
      const { data } = await api.get('/materials');
      setMaterialsOptions(formatToSelectOption(data));
    })();
  }, [loading]);

  function handleUploadImageButtonClick() {
    inputUploadImageRef.current.click();
  }

  function handleInputFileChange(e) {
    if (e.target.files) {
      setNewFile(true);
      setFile(e.target.files[0]);
    }
  }

  function handleImageInputChange(e) {
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (e.target.files) {
      const files = [...e.target.files].filter(f => {
        if (f.size > maxSize) {
          Notification({
            message: 'Tamanho máximo permitido é de 5MB',
            title: 'Arquivo muito grande',
            type: Notification.types.ERROR,
          });
          return false;
        }

        return true;
      });

      setAddedImages([...addedImages, ...files]);
      const imagesUrl = files.map(f => ({
        url: window.URL.createObjectURL(f),
      }));
      setImages([...images, ...imagesUrl]);
    }
  }

  function validateForm(data) {
    if (!data.categories || data.categories.length === 0) {
      formRef.current.setFieldError(
        'categories',
        'Selecione pelo menos uma categoria'
      );
      return false;
    }

    if (!data.materials || data.materials.length === 0) {
      formRef.current.setFieldError(
        'materials',
        'Selecione pelo menos um material'
      );
      return false;
    }

    if (images.length === 0) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Selecione pelo menos uma imagem',
      });
      return false;
    }

    if (!file) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Selecione o arquivo do projeto',
      });

      return false;
    }

    return true;
  }

  async function editProject(data) {
    try {
      setLoading(true);
      formRef.current.setErrors({});

      const formData = new FormData();
      formData.append('project', JSON.stringify(data));

      if (isNewFile) {
        formData.append('projectFile', file);
      }

      if (isNewPdf) {
        formData.append('projectPdf', pdf);
      }

      if (addedImages.length > 0) {
        addedImages.forEach(i => formData.append('projectImages', i));
      }

      if (excludedImages.length > 0) {
        await Promise.all(
          excludedImages.map(imageId =>
            api.delete(`/project/${id}/image/${imageId}`)
          )
        );
      }

      await api.put(`/admin/project/${id}`, formData);

      Notification({
        type: Notification.types.SUCCESS,
        message: 'Projeto alterado com sucesso',
      });
    } catch (err) {
      if (err.response && err.response.data.errors) {
        formRef.current.setErrors(err.response.data.errors);

        return;
      }

      const error = err.response
        ? err.response.data.error
        : 'Erro ao editar projeto';

      Notification({
        type: Notification.types.ERROR,
        message: error,
      });
    } finally {
      setLoading(false);
    }
  }

  function handleFormSubmit(data) {
    formRef.current.setErrors({});
    if (validateForm(data)) {
      const materials = data.materials.map(m => ({ id: m.value }));
      const categories = data.categories.map(m => ({ id: m.value }));

      editProject({ ...data, materials, categories });
    }
  }

  function handleDeleteImageClick(i) {
    setExcludedImages([...excludedImages, i.id]);
    const filteredImages = images.filter(image => image.url !== i.url);
    setImages(filteredImages);
  }

  function handleUploadProjectPdfButtonClick(e) {
    e.preventDefault();
    inputUploadProjectPdfRef.current.click();
  }

  function handleDownloadProjectPdfButtonClick(e) {
    e.preventDefault();
    if (pdf) {
      window.open(pdf.url);
    }
  }

  function handleInputPdfChange(e) {
    if (e.target.files) {
      setNewPdf(true);
      setPdf(e.target.files[0]);
    }
  }

  function handleUploadProjectFileButtonClick(e) {
    e.preventDefault();
    inputUploadProjectFileRef.current.click();
  }

  async function handleDownloadProjectFileButtonClick(e) {
    e.preventDefault();

    try {
      const response = await api.get(`/project/${id}/file`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'project-file-svg.svg');
      link.click();
    } catch (err) {
      Notification({
        message: err.message,
        title: 'Erro ao baixar arquivo',
        type: Notification.types.ERROR,
      });
    }
  }

  function handleProjectSituationSelect(e) {
    if (e.value !== 'Em análise' && e.value !== projectSituation) {
      setSendMail(true);
    }

    setProjectSituation(e.value);
  }

  function handleMailCheckboxChange(e) {
    setSendMail(e.target.checked);
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <PageHeader showAddButton={false} title="Editar projeto" />
        <Form ref={formRef} onSubmit={data => handleFormSubmit(data)}>
          <Input name="name" label="Nome do projeto" />
          <Input
            type="text"
            name="shortDescription"
            label="Descrição curta (Máx 150 caracteres)"
            maxLength="150"
          />
          <ScrollContainer vertical={false}>
            <AddFileInput
              ref={inputUploadImageRef}
              type="file"
              multiple="multiple"
              onChange={handleImageInputChange}
            />
            <AddImageButton
              title="Adicionar imagem"
              type="button"
              onClick={handleUploadImageButtonClick}
            >
              <AiOutlinePlus size={50} color="#BDBDBD" />
            </AddImageButton>
            {images &&
              images.map(i => (
                <ImageContainer>
                  <Image
                    src={i.url}
                    alt="Imagem do projeto"
                    draggable="false"
                  />
                  <IoMdCloseCircle
                    size={20}
                    color="red"
                    onClick={() => handleDeleteImageClick(i)}
                  />
                </ImageContainer>
              ))}
          </ScrollContainer>
          <Input
            name="videoUrl"
            label="URL do vídeo de demonstração do projeto"
          />

          <Input
            step=".01"
            type="number"
            name="price"
            label="Preço"
            width={20}
          />
          <InputSelect
            name="categories"
            options={categoriesOptions}
            label="Categorias"
            placeholder="Selecione as categorias"
            noOptionsMessage="Sem categorias"
            isMulti
            isLoading={categoriesOptions === null}
          />
          <InputSelect
            name="materials"
            options={materialsOptions}
            label="Materiais"
            placeholder="Selecione os materiais"
            noOptionsMessage="Sem materiais"
            isMulti
            isLoading={materialsOptions === null}
          />

          <InputContainer>
            <AddFileInput
              ref={inputUploadProjectFileRef}
              type="file"
              onChange={handleInputFileChange}
            />
            <Input
              name="filename"
              label="Arquivo do projeto"
              disabled
              value={file.name}
            />
            {file && (
              <Button
                text="Download"
                onClick={handleDownloadProjectFileButtonClick}
              />
            )}
            <Button
              text="Upload"
              onClick={handleUploadProjectFileButtonClick}
            />
          </InputContainer>
          <InputContainer>
            <AddFileInput
              ref={inputUploadProjectPdfRef}
              type="file"
              onChange={handleInputPdfChange}
            />
            <Input
              name="pdfFilename"
              label="Arquivo PDF"
              disabled
              value={pdf.name}
            />
            {pdf && (
              <Button
                text="Download"
                onClick={handleDownloadProjectPdfButtonClick}
              />
            )}
            <Button text="Upload" onClick={handleUploadProjectPdfButtonClick} />
          </InputContainer>
          <InputSelect
            name="situation"
            options={ProjectSituations}
            label="Situação do projeto"
            noOptionsMessage="Sem resultados"
            onChange={handleProjectSituationSelect}
          />
          {projectSituation === 'Verificar' && (
            <Input
              name="reproofReason"
              label="Motivo da reprovação"
              multiline
            />
          )}
          <Input name="tutorialUrl" label="URL do vídeo tutorial" />
          <Input
            name="longDescription"
            label="Descrição longa"
            multiline
            style={{ marginBottom: 10 }}
          />
          <InputCheckBox
            type="checkbox"
            name="featured"
            label="Projeto destaque"
          />
          <InputCheckBox type="checkbox" name="premium" label="Projeto PRO" />
          <InputCheckBox
            type="checkbox"
            name="sendMail"
            label="Enviar email para o designer com a situação do projeto"
            checked={sendMail}
            onChange={handleMailCheckboxChange}
          />
          <Button type="submit" text="Salvar alterações" primary />
        </Form>
      </Container>
    </>
  );
};

export default EditProject;
