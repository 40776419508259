import SideMenu from '../../../components/SideMenu';
import Header from '../../../components/Header';

import { Main, Container } from './styles';

const LoggedLayout = ({ children }) => (
  <Container>
    <Header />
    <SideMenu />
    <Main>{children}</Main>
  </Container>
);

export default LoggedLayout;
