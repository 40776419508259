import styled from 'styled-components';

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 2.2rem;
`;

const Text = styled.p`
  margin-bottom: 2rem;
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export { Headline, modalStyles, Text };
