import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  h2 {
    margin-bottom: 4rem;
  }

  p {
    font-weight: 700;
  }
`;

const Info = styled.div`
  margin-left: 5rem;
  margin-top: 1.5rem;

  > div {
    display: flex;

    :not(:last-child) {
      margin-bottom: 6rem;
    }

    > div:not(last-child) {
      margin-right: 8rem;
    }
  }

  p {
    font-weight: 700;
    margin-top: 0.5rem;
  }

  p,
  small {
    color: #9a9a9a;
  }
`;

const ImageContainer = styled.div`
  border-right: 2px solid #e2e2e2;
  padding-right: 5rem;
  object-fit: contain;
  display: flex;
  flex-direction: column;

  img {
    border-radius: 50%;
    max-width: 15rem;
    display: block;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  padding-top: 5rem;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;

  p {
    color: var(--primary-color);
    font-weight: 700;
    margin-right: 0.5rem;
    margin-top: 1px;
  }
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export {
  Container,
  Info,
  ImageContainer,
  InfoContainer,
  EditContainer,
  Header,
};
