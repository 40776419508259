import styled from 'styled-components';

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  border-bottom: 1px solid #f2f2f2;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  flex: 1;

  input {
    border: 1px var(--primary-color) solid;
    border-radius: 12px;
    width: 50%;
    min-width: 300px;
  }

  svg {
    position: relative;
    right: 40px;
    z-index: 1;
  }
`;

const UserImage = styled.img`
  object-fit: cover;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    font-weight: 500;
    color: #9a9a9a;
    margin-left: 1.5rem;
  }
`;

export { Container, InputContainer, UserImage, UserInfo };
