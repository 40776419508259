import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';

import {
  Headline,
  Input,
  InputContainer,
  ButtonsContainer,
  modalStyles,
} from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    name: PropTypes.string,
    storeLink: PropTypes.string,
  }),
};

const defaultProps = {
  defaultValue: {
    name: '',
    storeLink: '',
  },
};

const modalCloseTimeout = 500;

const ModalWithTwoInput = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  text,
  defaultValue,
}) => {
  const [storeLink, setLink] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    setLink(defaultValue.storeLink);
    setName(defaultValue.name);
  }, [defaultValue]);

  function handleConfirmButtonClick() {
    if (!name) return;
    onConfirm({ name, storeLink });
    setTimeout(() => {
      setName('');
      setLink('');
    }, modalCloseTimeout);
  }

  function handleCancelButtonClick() {
    onClose();
    setTimeout(() => {
      setName('');
      setLink('');
    }, modalCloseTimeout);
  }

  function handleInputLinkChange(e) {
    setLink(e.target.value);
  }

  function handleInputNameChange(e) {
    setName(e.target.value);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      style={modalStyles}
      closeTimeoutMS={modalCloseTimeout}
    >
      <Headline>{title}</Headline>
      <p>{text}</p>
      <InputContainer>
        <Input
          autoFocus
          type="text"
          onChange={handleInputNameChange}
          value={name}
          placeholder="Nome"
        />
        <Input
          type="text"
          onChange={handleInputLinkChange}
          value={storeLink}
          placeholder="Link para a loja"
        />
      </InputContainer>
      <ButtonsContainer>
        <Button text="Cancelar" onClick={handleCancelButtonClick} />
        <Button text="Confirmar" primary onClick={handleConfirmButtonClick} />
      </ButtonsContainer>
    </Modal>
  );
};

ModalWithTwoInput.propTypes = propTypes;
ModalWithTwoInput.defaultProps = defaultProps;

export default ModalWithTwoInput;
