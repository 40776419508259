import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  width: 100vw;
  object-fit: cover;
`;

const BlueEllipse = styled.div`
  background-color: var(--primary-color);
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 85%);
  width: 120%;
  height: 70%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
`;

const LoginContainer = styled.main`
  margin: auto;
  margin-top: calc(50vh - (42rem / 2));
  z-index: 10;
  background-color: white;
  width: 43rem;
  height: 42rem;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.11);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 7rem;
  form {
    width: 100%;

    button {
      margin: 3rem auto 0 auto;
    }
  }
`;

const Logo = styled.img`
  width: 15rem;
  margin-bottom: 2rem;
`;

export { Container, Image, BlueEllipse, LoginContainer, Logo };
