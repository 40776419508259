import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddButton = styled.button`
  background-color: transparent;
  color: var(--primary-color);
  border: none;
  border-radius: 15px;
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0 2rem;

  :hover {
    background-color: #fafdff;
    opacity: 0.8;
  }

  svg {
    margin-left: 1rem;
    margin-top: 1px;
  }
`;

export { Header, AddButton };
