import { useEffect, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import Notification from '../../components/Notification';
import UserInfoModal from '../../components/UserInfoModal';
import { useAuth } from '../../hooks/auth';
import { SortTypes } from '../../hooks/sortableData';
import api from '../../services/api';

import { Container } from './styles';
import AlertModal from '../../components/AlertModal';

const itemLabels = {
  amount: { label: 'Valor', dataType: SortTypes.MONEY },
  createdAt: { label: 'Pedido feito em', dataType: SortTypes.DATE },
  userName: { label: 'Propriétario' },
  paid: { label: 'Pago' },
  paidAt: { label: 'Pago em', dataType: SortTypes.DATE },
};

const WithdrawalRequests = () => {
  const { loading } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [isUserInfoModalOpen, setUserInfoModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState({});

  async function fetchWithdrawalRequests() {
    try {
      const response = await api.get('/admin/withdrawal_requests');

      const formattedWithdrawalRequests = response.data.map(wr => ({
        ...wr,
        paid: wr.paidAt ? 'Sim' : 'Não',
        userName: wr.user.name,
      }));
      setWithdrawalRequests(formattedWithdrawalRequests);
    } catch (err) {
      Notification({
        message: 'Erro ao buscar pedidos de saque',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) {
      fetchWithdrawalRequests();
    }
  }, [loading]);

  function handleEyeButtonClick(wr) {
    setSelectedWithdrawal(wr);
    setUserInfoModalOpen(true);
  }

  function handleUserInfoModalClose() {
    setUserInfoModalOpen(false);
  }

  function handleDeleteButtonClick(wr) {
    setSelectedWithdrawal(wr);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/withdrawal_request/${selectedWithdrawal.id}`);
      Notification({
        message: 'Pedido de saque excluído com sucesso',
        type: Notification.types.SUCCESS,
      });
      fetchWithdrawalRequests();
    } catch (err) {
      Notification({
        message: 'Erro ao excluir pedido de saque',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirmModalConfirm() {
    try {
      setConfirmModalOpen(false);
      setLoading(true);
      await api.put(`/admin/withdrawal_request/${selectedWithdrawal.id}`, {
        paidAt: new Date(),
      });
      Notification({
        message: 'Pedido de saque finalizado com sucesso',
        type: Notification.types.SUCCESS,
      });
      fetchWithdrawalRequests();
    } catch (err) {
      Notification({
        message: 'Erro ao finalizar pedido de saque',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  function handleCheckButtonClick(wr) {
    setSelectedWithdrawal(wr);
    setConfirmModalOpen(true);
  }

  function handleConfirmModalClose() {
    setConfirmModalOpen(false);
  }

  return (
    <>
      <UserInfoModal
        isOpen={isUserInfoModalOpen}
        onClose={handleUserInfoModalClose}
        user={selectedWithdrawal.user}
      />
      <AlertModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title="Excluir pedido de saque"
        text={`Deseja realmente excluir o pedido de saque no valor de ${selectedWithdrawal.amount}?`}
        type="delete"
      />
      <AlertModal
        isOpen={isConfirmModalOpen}
        onClose={handleConfirmModalClose}
        onConfirm={handleConfirmModalConfirm}
        title="Finalizar pedido de saque"
        text="Deseja realmente marcar este pedido de saque como pago?"
        type="confirm"
      />
      <Container>
        <PageHeader title="Pedidos de saque" showAddButton={false} />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={withdrawalRequests}
            headers={itemLabels}
            showEdit={false}
            onDelete={handleDeleteButtonClick}
            showEye
            onEye={handleEyeButtonClick}
            showCheck
            onCheck={handleCheckButtonClick}
            checkButtonTitle="Mudar para pago"
          />
        )}
      </Container>
    </>
  );
};

export default WithdrawalRequests;
