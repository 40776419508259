import { useEffect, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import Notification from '../../components/Notification';
import EditConfigModal from '../../components/EditConfigModal';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import { Container } from './styles';

const itemLabels = {
  description: { label: 'Descrição' },
  value: { label: 'Valor' },
};

const Config = () => {
  const { loading } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [configs, setConfigs] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState({});

  async function fetchConfigs() {
    try {
      const response = await api.get('/admin/configs');
      setConfigs(response.data);
    } catch (err) {
      Notification({
        message: 'Erro ao buscar configurações',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) {
      fetchConfigs();
    }
  }, [loading]);

  function handleEditButtonClick(config) {
    setSelectedConfig(config);
    setEditModalOpen(true);
  }

  function handleEditConfigModalConfirm() {
    fetchConfigs();
    setEditModalOpen(false);
  }

  function handleEditConfigModalClose() {
    setEditModalOpen(false);
  }

  return (
    <>
      <EditConfigModal
        isOpen={editModalOpen}
        onConfirm={handleEditConfigModalConfirm}
        onClose={handleEditConfigModalClose}
        initialData={selectedConfig}
      />
      <Container>
        <PageHeader title="Configurações" showAddButton={false} />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={configs}
            headers={itemLabels}
            showDelete={false}
            onEdit={handleEditButtonClick}
          />
        )}
      </Container>
    </>
  );
};

export default Config;
