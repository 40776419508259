import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  background-color: ${({ warning, primary }) => {
    if (!primary) {
      return 'transparent';
    }

    if (warning) {
      return 'red';
    }

    return '#F39200';
  }};
  border-radius: 6px;
  border: ${({ warning, primary }) => {
    if (primary) {
      return 'none';
    }
    return warning ? '2px red solid' : '2px #F39200 solid';
  }};
  transition: background-color 1s, opacity 1s;

  :hover {
    background-color: ${({ warning, primary }) => {
      if (warning) {
        if (primary) {
          return 'red';
        }
        return '#fff5f5';
      }
      return primary ? '#ffb84d' : '#ffeedb';
    }};
    opacity: ${props => (props.warning ? 0.6 : 1)};
    transition: background-color 0.3s, opacity 0.3s;
  }
`;

const ButtonText = styled.p`
  color: ${({ warning, primary }) => {
    if (warning) {
      if (primary) {
        return 'white';
      }
      return 'red';
    }
    return primary ? 'white' : '#F39200';
  }};
  font-weight: ${({ bold }) => (bold ? 700 : 500)};
  font-size: 1.5rem;
  width: 100%;
`;

export { StyledButton, ButtonText };
