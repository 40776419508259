/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { FiTrash, FiEdit, FiEye, FiList, FiCheck } from 'react-icons/fi';

import { useSortableData, SortTypes } from '../../hooks/sortableData';

import { Table, TableHeader } from './styles';

const propTypes = {
  headers: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func,
  onEye: PropTypes.func,
  onEdit: PropTypes.func,
  onReport: PropTypes.func,
  onCheck: PropTypes.func,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  showEye: PropTypes.bool,
  showReport: PropTypes.bool,
  showCheck: PropTypes.bool,
  reportButtonTitle: PropTypes.string,
  checkButtonTitle: PropTypes.string,
};

const defaultProps = {
  onDelete: () => {},
  onEye: () => {},
  onEdit: () => {},
  onReport: () => {},
  onCheck: () => {},
  showDelete: true,
  showEdit: true,
  showEye: false,
  showReport: false,
  showCheck: false,
  reportButtonTitle: 'Relatório',
  checkButtonTitle: '',
};

const IconColor = '#9A9A9A';

const SortableTable = ({
  items = [],
  headers,
  onDelete,
  onEye,
  onEdit,
  onReport,
  onCheck,
  showDelete,
  showEdit,
  showEye,
  showReport,
  reportButtonTitle,
  showCheck,
  checkButtonTitle,
}) => {
  const { sortedItems, requestSort, sortConfig } = useSortableData(items);

  return (
    <Table>
      <thead>
        <tr>
          {Object.keys(headers).map(header => (
            <TableHeader
              sorted={
                sortConfig && sortConfig.key === header ? sortConfig : null
              }
              onClick={() => requestSort(header, headers[header].dataType)}
            >
              {headers[header].label}
              <div className="arrow" />
            </TableHeader>
          ))}
          <TableHeader> </TableHeader>
        </tr>
      </thead>
      <tbody>
        {sortedItems.map(i => (
          <tr key={i.id}>
            {Object.keys(headers).map(h => {
              const type = headers[h].dataType;
              if (type === SortTypes.MONEY) {
                return <td>R${i[h]}</td>;
              }

              if (type === SortTypes.DATE) {
                if (i[h] === 0) return <td>Nunca</td>;

                if (!i[h]) return <td />;

                return <td>{new Date(i[h]).toLocaleDateString('pt-BR')}</td>;
              }

              return <td>{i[h]}</td>;
            })}
            <td>
              {showReport && (
                <FiList
                  size={20}
                  color={IconColor}
                  title={reportButtonTitle}
                  onClick={() => onReport(i)}
                />
              )}
              {showCheck && (
                <FiCheck
                  size={20}
                  color={IconColor}
                  title={checkButtonTitle}
                  onClick={() => onCheck(i)}
                />
              )}
              {showEye && (
                <FiEye
                  size={20}
                  color={IconColor}
                  title="Visualizar"
                  onClick={() => onEye(i)}
                />
              )}
              {showEdit && (
                <FiEdit
                  size={20}
                  color={IconColor}
                  title="Editar"
                  onClick={() => onEdit(i)}
                />
              )}
              {showDelete && (
                <FiTrash
                  size={20}
                  color={IconColor}
                  title="Excluir"
                  onClick={() => onDelete(i)}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

SortableTable.propTypes = propTypes;
SortableTable.defaultProps = defaultProps;

export default SortableTable;
