import { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { Form } from '@unform/web';

import Notification from '../../components/Notification';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import {
  Container,
  UploadImageContainer,
  ButtonsContainer,
  UserImage,
} from './styles';

const EditProfile = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const { userImage, updateUserImage, updateUserName, loading } = useAuth();
  const inputUploadImage = useRef();

  const [isLoading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (userImage) setImageUrl(userImage);
  }, [userImage]);

  async function fetchUserInfo() {
    try {
      const { data } = await api.get('/user');
      formRef.current.setData(data);
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao dados do usuário';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) {
      fetchUserInfo();
    }
  }, [loading]);

  function handleCancelButtonClick() {
    history.goBack();
  }

  async function uploadUserImage(file) {
    try {
      const data = new FormData();
      data.append('image', file);
      const response = await api.post('/images', data);
      Notification({
        type: Notification.types.SUCCESS,
        message: 'Imagem atualizada com sucesso',
      });
      updateUserImage(response.data.url);
    } catch (err) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Ocorreu um erro ao atualizar imagem',
      });
    }
  }

  function handleUpdateImageClick() {
    inputUploadImage.current.click();
  }

  function handleInputChange(e) {
    if (e.target.files) {
      try {
        const image = e.target.files[0];
        uploadUserImage(image);

        const url = window.URL.createObjectURL(image);
        setImageUrl(url);
      } catch (err) {
        Notification({ type: Notification.types.ERROR, message: err.message });
      }
    }
  }

  function validateForm(data) {
    if (!data.name || data.name.trim() === '') {
      formRef.current.setErrors({ name: 'Este campo é obrigatório' });
      return false;
    }

    if (!data.email || data.email.trim() === '') {
      formRef.current.setErrors({ name: 'Este campo é obrigatório' });
      return false;
    }

    return true;
  }

  async function handleSubmitForm(data) {
    if (validateForm(data)) {
      try {
        await api.put('/user', data);
        Notification({
          type: Notification.types.SUCCESS,
          message: 'Dados atualizados com sucesso',
        });
        formRef.current.setData({ name: data.name, email: data.email });
        updateUserName(data.name);
      } catch (err) {
        Notification({
          type: Notification.types.ERROR,
          message: 'Ocorreu um erro ao atualizar seus dados',
        });
      }
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <h2>Minha conta</h2>
        <p>Dados pessoais</p>
        <UploadImageContainer>
          <input
            ref={inputUploadImage}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleInputChange}
          />
          {imageUrl ? (
            <UserImage
              onClick={handleUpdateImageClick}
              src="https://github.com/Joaoo1.png"
              alt=""
            />
          ) : (
            <FaUserCircle color="#9a9a9a" size={150} />
          )}
        </UploadImageContainer>
        <Form ref={formRef} onSubmit={handleSubmitForm}>
          <Input name="name" label="Nome" />
          <Input name="email" label="E-mail" />
          <Input name="cpf" label="CPF" />
          <Input name="oldPassword" label="Senha atual" />
          <Input name="password" label="Nova senha" />
          <Input name="confirmPassword" label="Confirmar senha" />
          <ButtonsContainer>
            <Button text="Cancelar" onClick={handleCancelButtonClick} />
            <Button text="Salvar dados" primary type="submit" />
          </ButtonsContainer>
        </Form>
      </Container>
    </>
  );
};

export default EditProfile;
