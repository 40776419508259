import { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';

import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import InputCheckBox from '../../components/InputCheckBox';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import { Container, ButtonsContainer, InputContainer } from './styles';

function formatToSelectOption(arr) {
  return arr.map(i => ({ label: i.type, value: i.type }));
}
function formatTermsToSelectOption(arr) {
  return arr.map(i => ({ label: i.description, value: i.id }));
}

const AddUser = () => {
  const formRef = useRef(null);
  const { loading } = useAuth();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState(null);
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    if (!loading) {
      (async () => {
        try {
          const { data } = await api.get('/admin/user_types');
          setUserTypes(formatToSelectOption(data));
        } catch (err) {
          Notification({
            message: 'Erro ao buscar tipos de usuário',
            type: Notification.types.ERROR,
          });
        }
      })();
      (async () => {
        try {
          const { data } = await api.get('/admin/terms');
          setTerms(formatTermsToSelectOption(data));
        } catch (err) {
          Notification({
            message: 'Erro ao buscar termos',
            type: Notification.types.ERROR,
          });
        }
      })();
    }
  }, [loading]);

  async function handleSubmit(data) {
    formRef.current.setErrors({});
    try {
      await api.post('/admin/users', data);
      Notification({
        message: 'Usuário criado com sucesso',
        type: Notification.types.SUCCESS,
      });
      history.goBack();
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
        if (err.response.data.errors) {
          formRef.current.setErrors(err.response.data.errors);
        }
      } else {
        Notification({
          message: 'Erro ao criar usuário',
          type: Notification.types.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <PageHeader showAddButton={false} title="Novo usuário" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <p>Dados pessoais</p>
          <Input name="name" label="Nome" />
          <Input name="email" label="E-mail" />
          <InputMask name="cpf" label="CPF" mask="999.999.999-99" />
          <InputContainer>
            <Input
              id="birthdate"
              type="date"
              name="birthDate"
              label="Data de nascimento"
            />
            <InputSelect
              name="userType"
              options={userTypes}
              label="Tipo de usuário"
              noOptionsMessage="Nenhum resultado encontrado"
              isLoading={userTypes === null}
            />
          </InputContainer>
          <InputContainer>
            <Input type="password" name="password" label="Senha" />
            <Input
              type="password"
              name="confirmPassword"
              label="Confirmar senha"
            />
          </InputContainer>
          <InputSelect
            name="termId"
            options={terms}
            label="Termo de uso do usuário"
            noOptionsMessage="Nenhum resultado encontrado"
            isLoading={terms === null}
          />
          <InputCheckBox
            id="verified"
            type="checkbox"
            name="verified"
            label="Verificado"
          />
          <InputCheckBox
            type="checkbox"
            name="designerPremium"
            label="Designer Premium"
          />
        </Form>
        <ButtonsContainer>
          <Button
            text="Criar usuário"
            primary
            onClick={() => formRef.current.submitForm()}
          />
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default AddUser;
