import { useEffect, useState } from 'react';

import api from '../../services/api';
import { SortTypes } from '../../hooks/sortableData';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import DeleteModal from '../../components/AlertModal';
import AddVoucherModal from '../../components/AddVoucherModal';
import VoucherUsersModal from '../../components/VoucherUsersModal';
import EditVoucherModal from '../../components/EditVoucherModal';
import InfoModal from '../../components/InfoModal';

import { Container } from './styles';

const itemLabels = {
  expired: { label: 'Expirado' },
  expiresAt: { label: 'Expira em', dataType: SortTypes.DATE },
  maxUses: { label: 'Nº Máx. usos' },
  uses: { label: 'Usos' },
  owner: { label: 'Propriétario' },
  value: { label: 'Valor', dataType: SortTypes.MONEY },
  createdAt: { label: 'Criado em', dataType: SortTypes.DATE },
};

const Vouchers = () => {
  const { loading } = useAuth();
  const [vouchers, setVouchers] = useState([]);
  const [isLoading, setLoading] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [usersModalOpen, setUsersModalOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});

  async function fetchVouchers() {
    (async () => {
      try {
        const { data } = await api.get('/admin/vouchers');
        setVouchers(
          data.map(v => ({
            ...v,
            expired: v.expired ? 'Sim' : 'Não',
            owner: v.owner ? v.owner.name : 'Nenhum',
            ownerCpf: v.owner ? v.owner.cpf : '',
            expiresAt: v.expiresAt ? v.expiresAt : 0,
          }))
        );
      } catch (err) {
        const error = err.response
          ? err.response.data.error
          : 'Ocorreu um erro ao buscar vouchers';

        Notification({ message: error, type: Notification.types.ERROR });
      } finally {
        setLoading(false);
      }
    })();
  }

  useEffect(() => {
    if (!loading) {
      fetchVouchers();
    }
  }, [loading]);

  function handleDeleteButtonClick(user) {
    setSelectedVoucher(user);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
    setTimeout(() => setSelectedVoucher({}), DeleteModal.closeTimeout);
  }

  async function handleDeleteModalConfirm() {
    try {
      setDeleteModalOpen(false);
      setLoading(true);
      await api.delete(`/admin/voucher/${selectedVoucher.id}`);
      fetchVouchers();
      Notification({
        message: 'Voucher excluído com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const error = err.response
        ? err.response.data.error
        : 'Ocorreu um erro ao excluir voucher';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  function handleAddButtonClick() {
    setAddModalOpen(true);
  }

  function handleCloseAddModal() {
    setAddModalOpen(false);
  }

  function handleInfoModalClose() {
    setInfoModalOpen(false);
  }

  async function handleAddVoucherModalConfirm() {
    fetchVouchers();
    setAddModalOpen(false);
  }

  function handleEyeButtonClick(voucher) {
    setSelectedVoucher(voucher);
    setInfoModalOpen(true);
  }

  function handleEditButtonClick(voucher) {
    setSelectedVoucher(voucher);
    setEditModalOpen(true);
  }

  function handleEditVoucherModalConfirm() {
    fetchVouchers();
    setEditModalOpen(false);
  }

  function handleEditVoucherModalClose() {
    setEditModalOpen(false);
  }

  function handleReportButtonClick(v) {
    if (v.uses === 0) {
      Notification({
        message: 'Esse voucher ainda não foi utilizado',
        type: Notification.types.INFO,
      });
      return;
    }

    setSelectedVoucher(v);
    setUsersModalOpen(true);
  }

  function handleUsersModalClose() {
    setUsersModalOpen(false);
  }

  return (
    <>
      <VoucherUsersModal
        isOpen={usersModalOpen}
        onClose={handleUsersModalClose}
        voucherId={selectedVoucher.id}
      />
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title="Excluir voucher"
        text={`Deseja realmente excluir o voucher no valor de R$${selectedVoucher.value}?`}
        type="delete"
      />
      <AddVoucherModal
        isOpen={addModalOpen}
        onConfirm={handleAddVoucherModalConfirm}
        onClose={handleCloseAddModal}
      />
      <EditVoucherModal
        isOpen={editModalOpen}
        onConfirm={handleEditVoucherModalConfirm}
        onClose={handleEditVoucherModalClose}
        initialData={selectedVoucher}
      />
      <InfoModal
        title="Visualizar voucher"
        text={`Código para utilizar voucher: \n${selectedVoucher.token}`}
        isOpen={infoModalOpen}
        onClose={handleInfoModalClose}
      />
      <Container>
        <PageHeader
          title="Vouchers"
          buttonText="Adicionar novo voucher"
          onAddButtonClick={handleAddButtonClick}
        />
        {isLoading ? (
          <div className="loader" />
        ) : (
          <Table
            items={vouchers}
            headers={itemLabels}
            onDelete={handleDeleteButtonClick}
            onEdit={handleEditButtonClick}
            showEye
            onEye={handleEyeButtonClick}
            showReport
            onReport={handleReportButtonClick}
            reportButtonTitle="Usuários que utilizaram o voucher"
          />
        )}
      </Container>
    </>
  );
};

export default Vouchers;
