import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';

import { Headline, ButtonsContainer, modalStyles, Container } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};

const VoucherUsersModal = ({ isOpen, onClose, user }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Usuários do voucher"
    style={modalStyles}
    closeTimeoutMS={500}
  >
    <Container>
      <Headline>Informações do usuário solicitante</Headline>
      <h3>Dados do usuário</h3>
      <p>
        <strong>Nome:</strong>
        {` ${user?.name || ''}`}
      </p>
      <p>
        <strong>CPF:</strong>
        {` ${user?.cpf || ''}`}
      </p>
      <p>
        <strong>Email:</strong>
        {` ${user?.email || ''}`}
      </p>
      <h3>Dados bancários</h3>
      <p>
        <strong>Nome do destinatário:</strong>
        {` ${user?.recipientName || ''}`}
      </p>
      <p>
        <strong>Código do banco:</strong>
        {` ${user?.bankCode || ''}`}
      </p>
      <p>
        <strong>Tipo de conta:</strong>
        {` ${user?.accountType || ''}`}
      </p>
      <p>
        <strong>Agência:</strong>
        {` ${user?.agency || ''}`}
      </p>
      <p>
        <strong>Conta:</strong>
        {` ${user?.account || ''}`}
      </p>
      <p>
        <strong>Operação:</strong>
        {` ${user?.operation || ''}`}
      </p>
      <ButtonsContainer>
        <Button text="Fechar" onClick={onClose} />
      </ButtonsContainer>
    </Container>
  </Modal>
);

VoucherUsersModal.propTypes = propTypes;

export default VoucherUsersModal;
