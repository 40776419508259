import { useMemo, useState } from 'react';

const SortTypes = {
  ALPHABETICAL: 'alphabetical',
  DATE: 'date',
  MONEY: 'money',
};

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      if (sortConfig.sortType === SortTypes.ALPHABETICAL) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ASC' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ASC' ? 1 : -1;
          }
          return 0;
        });
      } else if (sortConfig.sortType === SortTypes.DATE) {
        sortableItems.sort((a, b) => {
          if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
            return sortConfig.direction === 'ASC' ? -1 : 1;
          }
          if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
            return sortConfig.direction === 'ASC' ? 1 : -1;
          }
          return 0;
        });
      } else if (sortConfig.sortType === SortTypes.MONEY) {
        sortableItems.sort((a, b) => {
          if (
            parseInt(a[sortConfig.key], 10) < parseInt(b[sortConfig.key], 10)
          ) {
            return sortConfig.direction === 'ASC' ? -1 : 1;
          }
          if (
            parseInt(a[sortConfig.key], 10) > parseInt(b[sortConfig.key], 10)
          ) {
            return sortConfig.direction === 'ASC' ? 1 : -1;
          }
          return 0;
        });
      }
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, sortType = SortTypes.ALPHABETICAL) => {
    let direction = 'ASC';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ASC'
    ) {
      direction = 'DESC';
    }
    setSortConfig({ key, direction, sortType });
  };

  return { sortedItems, requestSort, sortConfig };
};

export { useSortableData, SortTypes };
