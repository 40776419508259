import Modal from 'react-modal';
import { useRef, useState } from 'react';
import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import api from '../../services/api';
import Button from '../Button';
import Input from '../Input';
import InputMask from '../InputMask';
import Notification from '../Notification';

import { Headline, ButtonsContainer, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const AddVoucherModal = ({ isOpen, onClose, onConfirm }) => {
  const formRef = useRef(null);

  const [isLoading, setLoading] = useState(false);

  async function handleSubmitForm(data) {
    try {
      setLoading(true);
      await api.post(`/admin/vouchers`, data);
      Notification({
        message: 'Voucher adicionado com sucesso',
        type: Notification.types.SUCCESS,
      });
      onConfirm();
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
        if (err.response.data.errors) {
          formRef.current.setErrors(err.response.data.errors);
        }
      } else {
        Notification({
          message: 'Erro ao criar voucher',
          type: Notification.types.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Adicionar voucher"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      {isLoading && <div className="loader" />}
      <Headline>Adicionar voucher</Headline>
      <Form onSubmit={handleSubmitForm} ref={formRef}>
        <Input type="number" label="Valor do voucher" name="value" />
        <Input
          type="number"
          label="Dias para expirar voucher"
          name="expiresAtDays"
        />
        <Input type="number" label="Nº máximo de usos" name="maxUses" />
        <InputMask
          name="ownerCpf"
          label="CPF do proprietário"
          mask="999.999.999-99"
        />
        <ButtonsContainer>
          <Button text="Cancelar" onClick={onClose} />
          <Button text="Confirmar" primary type="submit" />
        </ButtonsContainer>
      </Form>
    </Modal>
  );
};

AddVoucherModal.propTypes = propTypes;

export default AddVoucherModal;
