import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 700px;

  h2 {
    margin-bottom: 4rem;
  }

  p {
    font-weight: 700;
  }
`;

const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0rem;
`;

const UserImage = styled.img`
  width: 15rem;
  height: 15rem;
  border-radius: 50%;

  :hover {
    opacity: 0.8;
  }
`;

const UploadImageButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.4rem;

  :hover {
    background-color: #fafdff;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 2rem;
  }
`;

export {
  Container,
  UploadImageContainer,
  UploadImageButton,
  ButtonsContainer,
  UserImage,
};
